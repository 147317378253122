import React from "react"
import { Flex, Text } from "@chakra-ui/react";

interface LabelTextProps {
  label: string,
  text: string,
}

export const LabelText: React.FC<LabelTextProps> = ({label, text}) => {
  return (
    <Flex justifyContent="space-between" w={"100%"}>
      <Text color="rgb(85, 104, 125)" fontSize="0.9rem">
        {label}
      </Text>
      <Text fontSize="0.9rem">
        {text}
      </Text>
    </Flex>
  )
}
