import React from "react"
import {Button} from "../components";
import {Card} from "../components/Card";
import {Box, Stack, Text, Heading, FormControl, FormErrorMessage,} from "@chakra-ui/react";
import {Input} from "../components/Input";
import {RiErrorWarningFill} from "react-icons/all";

interface VerificationScreenProps {
  isValid: boolean,
  showError: boolean,
  value: string,
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleOnAccept: () => void,
}
export const VerificationScreen: React.FC<VerificationScreenProps> = ({
  isValid,
  showError,
  value,
  handleOnChange,
  handleOnAccept,
}) => {

  return (
    <Card>
      <Stack align={'center'} justify={'center'} spacing={5}>
        <Stack align={'center'} justify={'center'} spacing={1}>

          <Text fontSize="0.825rem" color="rgb(54, 74, 97)" fontWeight="400">
            Bulletin de paie
          </Text>
          <Heading as="h5" size="sm" fontWeight="500">
            Vérification d'intégrité
          </Heading>

        </Stack>

        <Text justify={'center'} align={'center'} color="rgb(54, 74, 97)" fontSize="0.875rem">
          Entrez le code à 6 chiffres situé en-dessous du QR code sur le bulletin de paie.
        </Text>

        <FormControl isInvalid={!isValid && showError}>
          <Input value={value} onChange={handleOnChange}/>
          <FormErrorMessage>
            <Box mr={2}>
              <RiErrorWarningFill size={"1.1rem"}/>
            </Box>
            Code invalide
          </FormErrorMessage>
        </FormControl>
        <Button w="full" isDisabled={value === ''} onClick={handleOnAccept}>
          Valider
        </Button>

      </Stack>
    </Card>
  )
}


