import React from "react";
import { Button as ButtonChakra } from "@chakra-ui/react"
import { ButtonProps } from "@chakra-ui/button/dist/types/button";

export const Button: React.FC<ButtonProps> = (props) => {
  return (
    <ButtonChakra
      {...props}
      _focus={{}}
      fontWeight="500"
      backgroundColor="#0f6fde"
      colorScheme="blue"
      fontSize='14px'
      _disabled={{ backgroundColor: "rgb(240, 243, 247) !important", cursor: "not-allowed", color: "rgb(150, 163, 179)" }}
    />
  )
}
