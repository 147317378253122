import { Input as ChakraInput } from '@chakra-ui/react'
import React from 'react'
import {InputProps} from "@chakra-ui/input/dist/types/input";

export const Input: React.FC<InputProps> = (props) => {
  return(
    <ChakraInput
      {...props}
      color="rgb(34, 57, 84)"
      fontSize="0.875rem"
      outline={1}
      _focus={{ borderColor: '#0f6fde' }}
    />
  )
}
