import React, {useState} from "react";
import {SalaryScreen} from "./SalaryScreen";
import {VerificationScreen} from "./VerificationScreen";
import {Box, Center, Image, Link, Text} from "@chakra-ui/react";

interface MainScreenProps {
  code: string,
  startPeriod: string,
  endPeriod: string,
  salaryExVAT: number,
  salaryIncVAT: number,
}

export const MainScreen: React.FC<MainScreenProps> = ({ code, endPeriod, startPeriod, salaryExVAT, salaryIncVAT }) => {
  const [value, setValue] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const [displaySalary, setDisplaySalary] = useState<boolean>(false);


  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue(newValue);
    setIsValid(newValue === code)
  }

  const handleOnAccept = () => {
    setShowError(true)

    if(isValid) {
      setDisplaySalary(true)
    }
  }

  return (
    <Center>
      <Box maxW={'21.5rem'}>
        <Center>
          <a href="https://payfit.com/fr">
            <Image
              src="/logo.2d4391b5.svg"
              h={9}
              justifyContent={"center"}
              mt={10}
            />
          </a>
        </Center>

        {displaySalary
            ? (
              <SalaryScreen
                startPeriod={startPeriod}
                endPeriod={endPeriod}
                salaryExVAT={salaryExVAT}
                salaryIncVAT={salaryIncVAT}
              />
            )
            : (
              <VerificationScreen
                isValid={isValid}
                showError={showError}
                value={value}
                handleOnChange={handleOnChange}
                handleOnAccept={handleOnAccept}
              />
            )
        }

        <Text color="rgb(85, 104, 125)" fontSize="0.7rem" justify={'center'} align={'center'}>
          Le scan du QR code vous permet de vérifier l’intégrité des informations présentes sur le bulletin de paie.
        </Text>
        <Center mt={2}>
          <Link href="https://payfit.com/fr" color="rgb(53, 135, 230)" fontSize="0.875rem" isExternal _focus={{}}>
            En savoir plus sur PayFit
          </Link>
        </Center>
      </Box>
    </Center>
  )
}
