import React from "react"
import {Alert, AlertIcon, Divider, Heading, Stack, Text} from "@chakra-ui/react";
import {Card} from "../components/Card";
import {LabelText} from "../components/LabelText";

interface SalaryScreenProps {
  startPeriod: string,
  endPeriod: string,
  salaryExVAT: number
  salaryIncVAT: number,
}

export const SalaryScreen: React.FC<SalaryScreenProps> = ({
  startPeriod,
  endPeriod,
  salaryExVAT,
  salaryIncVAT}) => {
  return (
    <Card>
      <Stack align={'center'} justify={'center'} spacing={5}>
        <Stack align={'center'} justify={'center'} spacing={1}>

          <Text fontSize="0.825rem" color="rgb(54, 74, 97)" fontWeight="400">
            PÉRIODE DU {startPeriod} AU {endPeriod}
          </Text>
          <Heading as="h5" size="sm" fontWeight="500" pb={4}>
            Informations du bulletin de paie
          </Heading>

          <Alert status="info" rounded={'3px'} backgroundColor="rgb(235, 252, 255)" color="rgb(0, 113, 128)" fontSize="0.825rem" lineHeight="1.4rem">
            <AlertIcon color="rgb(0, 191, 217)" />
            Comparez les données ci-dessous avec le bulletin de paie.
          </Alert>

        </Stack>

        <Stack spacing={3} w="100%" pb={2} align={'center'} justify={'center'}>
          <LabelText label="SIRET" text="88198501400011" />
          <LabelText label="Entreprise" text="GABZO SAS" />
          <LabelText label="Employé" text="Victor Duclos" />

          <Divider w={"50%"}/>

          <LabelText label="Net à payer avant impôt" text={salaryIncVAT.toString()} />
          <LabelText label="Salaire brut" text={salaryExVAT.toString()} />
        </Stack>

      </Stack>
    </Card>
  )
}
