import {
  Box,
  Center,
  useColorModeValue,
} from '@chakra-ui/react';
import React, {ReactNode} from 'react';

interface CardProps {
  children: ReactNode,
}

export const Card: React.FC<CardProps> = ({children}) => {
  return (
    <Center py={5}>
      <Box
        w={'full'}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow="rgb(0 0 0 / 12%) 0px 1px 3px 0px, rgb(19 45 74 / 4%) 0px 0px 0px 1px"
        rounded={'md'}
        overflow={'hidden'}
      >

        <Box p={6}>
          {children}
        </Box>

      </Box>
    </Center>
  );
}
