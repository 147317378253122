import * as React from "react"
import {ChakraProvider} from "@chakra-ui/react"

import {
  BrowserRouter as Router,
  Route,
  Switch,
  // @ts-ignore
} from "react-router-dom";
import theme from "./theme";
import {MainScreen} from "./screens/MainScreen";

export const App = () => (
  <ChakraProvider theme={theme}>
    <Router>
      <Switch>
        <Route path="/603c21b70e59b5e3acc0df9e-c1cd64c3cde68139c38e">
          <MainScreen
            code="619941"
            startPeriod="01/02/2021"
            endPeriod=" 28/02/2021"
            salaryExVAT={3750.00}
            salaryIncVAT={2885.70}/>
        </Route>
        <Route path="/608d81f2d851d5fe71060cad-b306948b6b7bab1fdd24">
          <MainScreen
            code="242915"
            startPeriod="01/03/2021"
            endPeriod=" 31/03/2021"
            salaryExVAT={3750.00}
            salaryIncVAT={2865.70}/>
        </Route>
        <Route path="/60882d55758ff568eb4b57ef-5c61ba809a383ecb0248">
          <MainScreen
            code="721452"
            startPeriod="01/04/2021"
            endPeriod=" 30/04/2021"
            salaryExVAT={3750.00}
            salaryIncVAT={2870.70}/>
        </Route>
        <Route path="/60b16d8916dfa0a0040c2ef2-dab61dbbf92a9cfb25dd">
          <MainScreen
            code="747592"
            startPeriod="01/05/2021"
            endPeriod="31/05/2021"
            salaryExVAT={3750.00}
            salaryIncVAT={2875.70}/>
        </Route>

        <Route path="*" render={() => {
          window.location.replace("https://payfit.com/fr");
          return null;
        }}>
        </Route>
      </Switch>
    </Router>
  </ChakraProvider>
)
